import { Reservation, SecureRescheduleReservationParams, SellingMode } from '@/services/sellerApiQuery/model';
import { Grid2, Stack, Typography, useMediaQuery } from '@planne-software/uni/mui/material';
import { format, lastDayOfMonth, addDays, subDays } from 'date-fns';
import { Alert } from '@planne-software/uni/Alert';
import { UniThemeProvider } from '@planne-software/uni/themes';
import { useMemo, useState, Fragment } from 'react';
import {
  StyledDatePickerLegend,
  StyledFormContent,
  StyledTimeContainer,
} from '@/features/reservation/components/RescheduleForm/RescheduleDateTimeSelector/styles';
import intervalTimeOptions from '@/features/agenda/utils/intervalTimeOptions';
import useAccount from '@/hooks/useAccount';
import { Controller, UseFormReturn } from 'react-hook-form';
import { RescheduleFormSchema } from '@/features/reservation/components/RescheduleForm/types';
import { useTranslation } from 'react-i18next';
import theme from '@/theme';
import useLoadReservationRescheduleSchedulings from '@/features/reservation/queries/useLoadReservationRescheduleSchedulings';
import getAvailableTimes from '@/features/reservation/utils/getAvailableTimes';
import TimeCellSelect from '@/features/reservation/components/RescheduleForm/RescheduleDateTimeSelector/TimeCellSelect';
import checkSoldOut from '@/features/reservation/utils/checkRescheduleSoldOut';
import checkRescheduleAvailable from '@/features/reservation/utils/checkRescheduleAvailable';
import TimeCellSelectSkeleton from '@/features/reservation/components/RescheduleForm/RescheduleDateTimeSelector/TimeCellSelect/TimeCellSelectSkeleton';
import TimeSelectInput from '@/features/reservation/components/RescheduleForm/RescheduleDateTimeSelector/TimeSelectInput';
import { SelectOption } from '@planne-software/uni/Select';
import { IconInfoTriangle, IconCalendar } from '@tabler/icons-react';
import { TimeIntervalStatus } from '@/features/reservation/types';
import { useCalendar } from '@/contexts/Calendar/hook';
import CalendarSelector from '@/features/reservation/components/RescheduleForm/RescheduleDateTimeSelector/CalendarSelector';
import { Button } from '@planne-software/uni/Button';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR as localeText } from '@mui/x-date-pickers/locales';
import { ptBR } from 'date-fns/locale';

type Props = {
  reservation: Reservation;
  isChaosMode: boolean;
  form: UseFormReturn<RescheduleFormSchema, unknown, SecureRescheduleReservationParams>;
};

const RescheduleDateTimeSelector = (props: Props) => {
  const { reservation, form, isChaosMode } = props;
  const { selectedAccount } = useAccount();
  const { t } = useTranslation(['reservation'], { keyPrefix: 'reschedule_form' });
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [useGetRescheduleSchedulings] = useLoadReservationRescheduleSchedulings();
  const { control, watch } = form;
  const [focusedDay, setFocusedDay] = useState<Date | null>(null);
  const compactCalendarWindow = useMemo(() => (isMobile ? 5 : 11), [isMobile]);
  const {
    activeDate: activeMonth,
    updateDate,
    firstDayOfRange,
    lastDayOfRange,
    calendarView,
    updateView,
  } = useCalendar();

  const scheduleDate = watch('scheduleDate');
  const scheduleTime = watch('scheduleTime');

  const isDateOnly = useMemo(
    () => reservation.product?.sellingMode === SellingMode.with_date_only,
    [reservation.product],
  );

  const isCalendarCompact = useMemo(() => calendarView === 15, [calendarView]);

  const firstDay = useMemo(
    () => (isCalendarCompact ? activeMonth : firstDayOfRange),
    [activeMonth, firstDayOfRange, isCalendarCompact, compactCalendarWindow],
  );
  const lastDay = useMemo(
    () => (isCalendarCompact ? addDays(activeMonth, compactCalendarWindow - 1) : lastDayOfRange),
    [activeMonth, lastDayOfRange, isCalendarCompact, compactCalendarWindow],
  );

  const rescheduleFrom = useMemo(() => format(firstDay, 'yyyy-MM-dd'), [firstDay, activeMonth]);
  const rescheduleUntil = useMemo(() => format(lastDay, 'yyyy-MM-dd'), [lastDay, activeMonth]);

  const preFetchBatches = useMemo(() => {
    const firstDayOfPastRange = isCalendarCompact
      ? subDays(firstDay, compactCalendarWindow)
      : new Date(activeMonth.getFullYear(), activeMonth.getMonth() - 1, 1);

    const lastDayOfPastRange = isCalendarCompact ? subDays(firstDay, 1) : lastDayOfMonth(firstDayOfPastRange);

    const firstDayOfNextRange = isCalendarCompact
      ? addDays(lastDay, 1)
      : new Date(activeMonth.getFullYear(), activeMonth.getMonth() + 1, 1);

    const lastDayOfNextRange = isCalendarCompact
      ? addDays(lastDay, compactCalendarWindow)
      : lastDayOfMonth(firstDayOfNextRange);

    return [
      { from: firstDayOfPastRange, to: lastDayOfPastRange },
      { from: firstDayOfNextRange, to: lastDayOfNextRange },
    ];
  }, [activeMonth, firstDay, lastDay, isCalendarCompact, compactCalendarWindow]);

  const {
    data: getAllResponse,
    isLoading,
    isFetching,
  } = useGetRescheduleSchedulings(reservation.id, rescheduleFrom, rescheduleUntil, preFetchBatches);

  const intervals = useMemo(
    () => intervalTimeOptions(selectedAccount?.app?.minuteSetInterval ?? 30),
    [selectedAccount],
  );

  const availabilityIntervals = useMemo(
    () => (scheduleDate ? getAvailableTimes(scheduleDate, intervals, isChaosMode, getAllResponse?.data) : []),
    [getAllResponse?.data, scheduleDate, intervals, isChaosMode],
  );

  const selectTimeOptions = useMemo(
    () =>
      availabilityIntervals.map((time) => ({
        label: time.label,
        value: time.label,
        status: time.status,
      })),
    [availabilityIntervals],
  );

  const timeOption = useMemo(
    () => availabilityIntervals.find((time) => time.label === scheduleTime),

    [scheduleTime, availabilityIntervals],
  );

  const isTimeSoldOut = useMemo(() => timeOption?.status === TimeIntervalStatus.UNAVAILABLE, [timeOption]);

  const isTimeWithoutReservation = useMemo(
    () => timeOption?.status === TimeIntervalStatus.WITHOUT_SCHEDULING,
    [timeOption],
  );

  const isDateWithoutSchedule = useMemo(
    () => !scheduleDate || !checkRescheduleAvailable(scheduleDate, getAllResponse?.data ?? []),
    [scheduleDate, getAllResponse],
  );

  const isDateSoldOut = useMemo(
    () => scheduleDate && checkSoldOut(scheduleDate, getAllResponse?.data ?? []),
    [scheduleDate, getAllResponse],
  );

  const onViewChange = (date: Date) => {
    updateDate(date);
  };
  const handleChangeCalendarView = () => {
    updateView(calendarView === 15 ? 30 : 15);
  };
  const handleFocusToday = () => {
    setFocusedDay(new Date());
  };

  return (
    <UniThemeProvider
      dateAdapter={AdapterDateFns}
      localeText={localeText.components.MuiLocalizationProvider.defaultProps.localeText}
      adapterLocale={ptBR}
    >
      <StyledFormContent>
        <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', gap: '1rem' }}>
          <Stack sx={{ flex: 1 }}>
            <Typography variant='text-md-semibold'>
              {isDateOnly ? t('date_time_selector.select_date') : t('date_time_selector.select_date_and_time')}
            </Typography>
            <StyledDatePickerLegend />
          </Stack>

          <Button type='button' variant='outlined' color='neutral' size='xs' onClick={() => handleFocusToday()}>
            <span>{t('today')}</span>
          </Button>
          <Button
            type='button'
            variant='outlined'
            color='primary'
            size='xs'
            startSlot={<IconCalendar />}
            onClick={() => handleChangeCalendarView()}
          >
            <span>{calendarView === 15 ? t('complete_calendar') : t('compact_calendar')}</span>
          </Button>
        </Stack>

        <Stack sx={{ flexDirection: isMobile || isCalendarCompact ? 'column' : 'row', gap: '1rem' }}>
          <Stack sx={{ gap: '1rem' }}>
            <Typography variant='text-sm-semibold'>{t('date_time_selector.date')}</Typography>
            <Controller
              control={control}
              defaultValue={new Date()}
              name='scheduleDate'
              render={({ field: { name, value, onChange } }) => (
                <Stack sx={{ alignItems: 'center' }} id={name}>
                  <CalendarSelector
                    value={value}
                    activeMonth={activeMonth}
                    isCalendarCompact={isCalendarCompact}
                    isLoading={isLoading}
                    isViewLoading={isFetching}
                    compactWindow={compactCalendarWindow}
                    focusedDate={focusedDay}
                    isChaosMode={isChaosMode}
                    getAllResponse={getAllResponse}
                    onChange={onChange}
                    onViewChange={onViewChange}
                  />
                </Stack>
              )}
            />
          </Stack>
          {isDateOnly ? (
            <Stack sx={{ gap: '1rem', width: '100%' }}>
              {isDateWithoutSchedule && isChaosMode ? (
                <Alert
                  severity='warning'
                  title={t('reservation_warning.without_scheduling.title')}
                  subTitle={t('reservation_warning.without_scheduling.message')}
                  startIcon={<IconInfoTriangle width={24} height={24} />}
                />
              ) : null}
              {isDateSoldOut && isChaosMode ? (
                <Alert
                  severity='warning'
                  title={t('reservation_warning.unavailable.title')}
                  subTitle={t('reservation_warning.unavailable.message')}
                  startIcon={<IconInfoTriangle width={24} height={24} />}
                />
              ) : null}
            </Stack>
          ) : (
            <Stack sx={{ gap: '1rem', width: '100%' }}>
              <Typography variant='text-sm-semibold'>{t('date_time_selector.time')}</Typography>
              {!isLoading ? (
                <Controller
                  control={control}
                  defaultValue={''}
                  name='scheduleTime'
                  render={({ field: { ref, name, value, onChange } }) =>
                    isChaosMode || calendarView === 15 ? (
                      <Fragment>
                        <TimeSelectInput
                          options={selectTimeOptions}
                          name={name}
                          onChange={(_, value) =>
                            'value' in (value as SelectOption)
                              ? onChange((value as SelectOption).value)
                              : onChange(value)
                          }
                          onClearInput={() => onChange(reservation.scheduleTime ?? '')}
                          value={value}
                          ref={ref}
                        />
                        {isTimeSoldOut || isTimeWithoutReservation ? (
                          <Alert
                            severity='warning'
                            title={t(`reservation_warning.${timeOption?.status.toLowerCase()}.title`)}
                            subTitle={t(`reservation_warning.${timeOption?.status.toLowerCase()}.message`)}
                            startIcon={<IconInfoTriangle width={24} height={24} />}
                          />
                        ) : null}
                      </Fragment>
                    ) : (
                      <StyledTimeContainer id={name}>
                        <Grid2 container sx={{ gap: '0.25rem', overflow: 'auto' }} columns={7}>
                          {availabilityIntervals.map((time) => (
                            <Grid2 size={1} key={time.label}>
                              <TimeCellSelect
                                selected={value === time.label}
                                color='success'
                                disabled={time.available}
                                onClick={() => onChange(time.label)}
                              >
                                {time.label}
                              </TimeCellSelect>
                            </Grid2>
                          ))}
                        </Grid2>
                      </StyledTimeContainer>
                    )
                  }
                />
              ) : (
                <TimeCellSelectSkeleton />
              )}
            </Stack>
          )}
        </Stack>
      </StyledFormContent>
    </UniThemeProvider>
  );
};

export default RescheduleDateTimeSelector;
